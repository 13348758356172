<template>
    <van-popup  v-model="show" :overlay="videoShow">
        <div class="bailu" v-show="videoShow">
            <div class="cancel" @click="verify"></div>
            <video v-if="url"  autoplay id="video" :x5-video-player-fullscreen="true" :controls="true" :src="url" class="videoBox"></video>
        </div>
    </van-popup>
</template>

<script>
export default {
    components: {},
    props: ['popupData'],
    data() {
        return {
            show: true,
            videoShow:false,
            url:''
        };
    },
    watch: {
    },
    computed: {},
    methods: {
        verify(){
            // var video = document.getElementById("video");
            // console.log(video)
            // video.pause()
            
            this.videoShow = false
            this.url = ''
        },
        play(){
            this.videoShow = true
            let that = this
            console.log('执行')
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            // if (isiOS) {
                setTimeout(()=>{
                    // 假设你的视频元素有一个ID为"myVideo"
                    var video = document.getElementById('video');
                    // 添加监听器来监听全屏变化
                    video.addEventListener('fullscreenchange', function() {
                            console.log('22222222222222222')
                            if (!document.webkitIsFullScreen) {
                                // 当退出全屏时，这里的代码会被执行
                                video.pause()
                                that.videoShow = false
                                that.url = ''
                                console.log('退出了全屏模式');
                            }
                        });
                },500)

            // }

            // video.play()

        }
    },
    created() {},
    mounted() {},
};
</script>
<style scoped>
.van-popup {
    /* width: 100% !important;
    height: 100vh !important;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: 6.1rem; */
    position: fixed;
    z-index: 501;
    background: rgba(0, 0, 0, 0);
}
.bailu{
    width: 100vw;
    height: 100vh;
    position: relative;
    
}
.videoBox{
    width: 100vw;
    height: 100vh;
}
video::-webkit-media-controls-fullscreen-button {
     display: none;
}
.cancel{
    width: .4rem;
    height: .4rem;
    position: absolute;
    top: 1rem;
    right: .5rem;
    background-image: url(~@/assets/images/cancel.png);
    background-size: 100% auto;
    background-position: top;
    background-repeat: no-repeat;
    z-index: 10000;
}
.verify{
    width: 1.2rem;
    height: .7rem;
    background-color: #0052D9;
    margin: .5rem auto 0 auto;
    border-radius: .2rem;
    color: #fff;
    text-align: center;
    line-height: .7rem;
}
.information{
    width: 5rem;
    margin-top: .2rem;
    margin-left: .2rem;
    display: flex;
}
.select{
    width: .4rem;
    height: .4rem;
    border-radius: 50%;
    border: .02rem solid #000;
    display: flex;
    justify-content: center;
    align-items: center;

}
.the{
    width: .2rem;
    height: .2rem;
    background-color: #000;
    border-radius: 50%;
}
.text{
    width: 4rem;
    margin-left: .2rem;
}
</style>