<template>
    <div class="wrapper">
        <!-- <div class="title">龙探长</div> -->
        <div class="content" ref="middle">
            <div class="objBox" v-for="(item,index) in newList" :key="index">
                <template v-if="item.roleType != 1">
                    <div class="avatar" v-show="item.roleType==2">
                        <img src="@/assets/images/img3.png" alt="">
                    </div>
                    <div :class="item.roleType==2?'leftinformation':'rightinformation'">
                        <!-- <div class="name" v-show="item.type==2">NPC</div>
                        <div class="rightname" v-show="item.type==3">玩家</div> -->
                        <div class="leftdialogue">
                            <template v-if="item.displayType == 1">
                                <div :class="item.roleType==2?'leftsj':'rightsj'"></div>
                                <div class="text">{{ item.text }}</div>
                            </template>
                            <template v-else-if="item.displayType==5">
                                <div class="videoBox">
                                    <!-- <img :src="item.bgImg" class="videoBox" v-show="item.bgImg" alt=""> -->
                                    <video id="video" :x5-video-player-fullscreen="true" :poster="item.bgImg" :ref="'video'+index" :src="item.imageUrl" class="videoBox"></video>
                                    <div class="button" @click="video(item,index)"></div>
                                    
                                </div>
                            </template>
                            <template v-else-if="item.displayType==6">
                                <div :class="item.roleType==2?'leftsj':'rightsj'"></div>
                                <div class="cardBox" @click="jump(item.link)">
                                    <div class="cardLeft">
                                        <div class="textBox">{{ item.title }}</div>
                                        <div class="jump" v-show="item.link"></div>
                                        
                                    </div>
                                    <div class="cardRight" v-show="item.imageUrl">
                                        <img :src="item.imageUrl" alt="">
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>
                    <div class="avatar" v-show="item.roleType==3">
                        <img src="@/assets/images/img2.png" alt="">
                    </div>

                </template>
                <template v-else>
                    <div class="tips">{{ item.text }}</div>
                </template>

            </div>
        </div>
        <div class="inputBox" v-show="popupData.length>0&&ispopupData">
            <div class="selectList"  >
                <div class="selectBox" v-for="(item,index) in popupData" :key="index" @click="selectIndex = index">
                    <div :class="selectIndex == index?'selection':'select'" >
                        <!-- <div class="selection" v-show="selectIndex == index"></div> -->
                        {{ item.text }}
                    </div>
                </div>


            </div>
            <div class="sendBox">
                <div class="tips">点击上方选项回答</div>
                <div class="send" @click="getEcho"></div>
            </div>
            
        </div>
        <popup ref="popup" />
    </div>
  </template>
  
  <script>
  import { getSessionNew,getEvent,getEcho } from '@/api'
  import popup from '@/components/popup.vue'

  
  
  
  
  export default {
    components: { 
        popup
     },
    props: {},
    data() {
      return {
        newList:[],
        list:[],
        time:null,
        listNUm:0,
        num:0,
        sessionId:'',
        time2:null,
        popupData:[],
        selectIndex:-1,
        ispopupData:false,
        names:'http://192.168.42.43:1234',
        img:'',
        lastRecordId:''
        

      }
    },
    watch: {},
    computed: {},
    methods: {
        jump(url){
            console.log(this.lastRecordId?url+'?lastRecordId='+this.lastRecordId:'')
            // let data = 
            location.href =this.lastRecordId?url+'?lastRecordId='+this.lastRecordId:''

        },
        getVideoCover(url) {
            console.log(url)
            let that = this
            return new Promise(function (resolve, reject) {
                let dataURL = '';
                let video = document.createElement("video");
                video.setAttribute('crossOrigin', 'anonymous');//处理跨域
                video.autoplay = true;
                video.playsInline = true;
                video.playsinline = true
                video.style.display = "none";
                video.muted = true
                // video.x5-video-player-fullscreen = true;
                //video.setAttribute('x5-video-player-fullscreen',false)
                video.setAttribute('src', url);
                video.setAttribute('width', 300);
                video.setAttribute('height', 450);
                video.currentTime = 0.1
                video.addEventListener('loadeddata', function () {
                    let canvas = document.createElement("canvas"),
                        width = video.width, //canvas的尺寸和图片一样
                        height = video.height;
                    canvas.width = width;
                    canvas.height = height;
                    console.log('111')
                    canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
                    console.log('222')
                    dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
                    resolve(dataURL);
                });
            })
        },
        video(item,index){
            let id = 'video' + index
            console.log(this.$refs[id],'222')
            // this.$refs[id].play()
            let video = document.getElementById('video')
            console.log(video)
            const u = navigator.userAgent;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isiOS) {   
                video.play()
                video.setAttribute('x5-video-player-fullscreen',true)
            }else{
                this.getVideoCover(item.imageUrl)
                this.$refs.popup.url = item.imageUrl
                this.$refs.popup.show = true
                this.$refs.popup.play()
            }

            // this.getVideoCover(item.imageUrl)
            // this.$refs.popup.url = item.imageUrl
            // this.$refs.popup.show = true
            // this.$refs.popup.play()

        },
        async reveal(){
            this.num = 0
            let arr = this.cutString(this.list[this.listNUm].text, 1);
            let bgImg = ''
            if(this.list[this.listNUm].displayType == 5){
                console.log('类型5')
                // let imageUrl = this.names + this.list[this.listNUm].imageUrl
                let imageUrl = this.list[this.listNUm].imageUrl
                let res = await this.getVideoCover(imageUrl)
                console.log(res)
                bgImg = res
            }
            console.log('zhixing')
            this.newList.push({
                roleType:this.list[this.listNUm].roleType,
                text:'',
                displayType:this.list[this.listNUm].displayType,
                title:this.list[this.listNUm].title,
                // imageUrl:this.names + this.list[this.listNUm].imageUrl,
                imageUrl:this.list[this.listNUm].imageUrl,
                link:this.list[this.listNUm].link,
                bgImg:bgImg
            })
            this.num = 0
            // console.log(this.listNUm)
            // console.log(this.num)
            this.time = setInterval(()=>{
                // if(this.list[this.listNUm].displayType == 5){
                //     debugger
                // }
                if(!arr[this.num]&&this.list[this.listNUm].text){
                    console.log(this.list)
                    console.log(this.newList)
                    console.log(arr)
                    console.log(this.listNUm)
                    console.log(this.num)
                    debugger
                }
                this.newList[this.listNUm].text = this.newList[this.listNUm].text + arr[this.num]
                
                let middle= this.$refs["middle"];
                middle.scrollTop = middle.scrollHeight;
                this.num++
                if((this.num == arr.length)||this.list[this.listNUm].displayType == 5){
                    
                    if(this.list[this.listNUm].roleType == 2){
                        this.ispopupData = true

                    }
                    console.log('222222222')
                    this.listNUm++
                    // if(this.listNUm<this.list.length){
                    //     setTimeout(()=>{
                    
                    this.getEvent()
                        // },500)
                    clearInterval(this.time)
                }
            },50)

            
        },
        cutString(str, len) {
            var result = [];
            for (var i = 0; i < str.length; i += len) {
                result.push(str.substring(i, i + len));
            }
            return result;
        },
        getSessionNew(){
            let param = {
                storyId:0,
                sceneId:0,
                eventId:0,
                ...sessionStorage.getItem('obj')?JSON.parse(sessionStorage.getItem('obj')):{}
            }
            console.log(param)
            getSessionNew(param).then(res=>{
                console.log(res)
                this.sessionId = res.data.data.sessionId
                this.polling()
            })
        },
        polling(){
            this.time2 = setInterval(()=>{
                this.getEvent()
            },1000)
        },
        getEcho(){
            let data = this.popupData[this.selectIndex]
            getEcho({
                sessionId:this.sessionId,
                eventId:data.id
            }).then(res=>{
                this.list.push({
                    roleType:3,
                    text:data.text,
                    displayType:1
                })
                this.ispopupData = false
                this.popupData = []
                this.selectIndex = -1
                this.reveal()
                this.polling()

            })

        },
        getEvent(){
            getEvent({
                sessionId:this.sessionId,
                eventId:0
            }).then(res=>{
                console.log(res)
                let data = res.data.data
                this.lastRecordId = data.lastRecordId
                if(data.eventType == 1){
                    clearInterval(this.time2)
                    this.time2 = null

                    //对话
                    this.list.push({
                        ...data.eventList[0]
                    })
                    console.log(this.list,'list+++++++++')
                    // this.list.push({
                    //     type:data.eventList[0].roleType,
                    //     text:data.eventList[0].text
                    // })

                    this.reveal()
                    // if(data.eventList[0].roleType == 1){
                    //     clearInterval(this.time2)
                    //     this.time2 = null
                    //     return
                    // }
                }else if(data.eventType == 2){
                    clearInterval(this.time2)
                    this.time2 = null
                    this.popupData = data.eventList
                    
                    //选项
                    // this.$refs.popup.show = true
                }else{
                    if(this.time2 == null){
                        this.polling()
                    }
                    // console.log(this.time2,'322222222222')
                    // setTimeout(()=>{
                    //     this.getEvent()

                    // },500)
                    

                }

            })

        }
  
    },
    created() { 
        // this.reveal()
        this.getSessionNew()
        
    
     },
    mounted() { }
  }
  </script>
  <style scoped lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #EBEBEB;
    
  }
  .customBtn{
    width: 100vw;
    height: 100vh;
  }
  .title{
    width: 100%;
    height: .8rem;
    font-size: .4rem;
    text-align: center;
    line-height: .8rem;
    font-weight: bold;
  }
  .inputBox{
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #F3F3F3;
    padding: .2rem 0;
    .send{
        width: .6rem;
        height: .6rem;
        background-image: url(~@/assets/images/send.png);
        background-size: 100% auto;
        background-position: top;
        background-repeat: no-repeat;
        margin-left: .2rem;
    }
    .sendBox{
        margin: .2rem auto 0 auto;
        width: 7rem;
        display: flex;
        .tips{
            height: .6rem;
            width: 5.8rem;
            background-color: #fff;
            border-radius: .2rem;
            padding-left: .2rem;
            line-height: .6rem;
            font-size: .26rem;
            color: #C9CACD;
        }
    }

    .selectList{
        width: 100%;
        // height: .5rem;
        // overflow-x: auto;
        display: flex;
        .selectBox{
            height: .6rem;
            display: flex;
            align-items: center;
        }
        .selection{
            box-sizing: border-box;
            position: relative;
            padding: .08rem .2rem;
            border-radius: .2rem;
            background-color: #fff;
            margin-left: .2rem;
            font-size: .24rem;
            text-align: center;
            line-height: .36rem;
            // max-height: .5rem;
            // height: .5rem;
            font-weight: bold;
            color: #E87354;
            border: .02rem solid #E87354;
            box-shadow:.01rem .01rem .05rem #E87354;
            display: flex;
            align-items: center;
            box-sizing: border-box;
        }
        .select{
            position: relative;
            padding: .08rem .2rem;
            border-radius: .2rem;
            background-color: #fff;
            margin-left: .2rem;
            font-size: .24rem;
            text-align: center;
            // height: .5rem;
            font-weight: bold;
            white-space: normal;
            word-break: break-all;
            display: flex;
            align-items: center;

            // .selection{
            //     width: .1rem;
            //     height: .1rem;
            //     background-color: red;
            //     border-radius: 50%;
            //     position: absolute;
            //     right: 0;
            //     top: 0;
            // }
        }
    }

  }
  .content{
    margin: 0 auto 0 auto;
    width: 100%;
    height: calc(95vh - 1.7rem);
    overflow-y: scroll;
  }
  .objBox{
    width: 7rem;
    display: flex;
    margin: .2rem auto 0 auto;

    .tips{
        max-width: 5rem;
        word-break: break-all;
        min-width: 2.5rem;
        min-height: .7rem;
        background-color: #BEBEBE;
        border-radius: .2rem;
        text-align: center;
        line-height: .7rem;
        color: #DEDEDE;
        font-size: .28rem;
        padding: .1rem .2rem;
        margin: .2rem auto 0 auto;
    }
    .name{
        width: 5rem;
        font-size: .28rem;
    }
    .rightname{
        width: 5rem;
        text-align: right;
        font-size: .28rem;
    }
    .avatar{
        min-width: 1rem;
        width: 1rem;
        height: 1rem;
        overflow: hidden;
        border-radius: .2rem;

        img{
            width: 100%;
            height: 100%;
        }
    }
    .leftsj{
        width: 0;
        border-right: .2rem solid #fff;
        border-top: .1rem solid transparent;
        border-bottom: .1rem solid transparent;
        position: absolute;
        left: -.18rem;
        top: .05rem;
    }
    .rightsj{
        width: 0;
        border-left: .2rem solid #95EC69;
        border-top: .1rem solid transparent;
        border-bottom: .1rem solid transparent;
        position: absolute;
        right: -.18rem;
        top: .05rem;
    }
    .rightinformation{
        width: 5rem;
        margin-left: .65rem;
        margin-top: .1rem;
        margin-right: .2rem;

        .leftdialogue{
            max-width: 5rem;
            min-height: .2rem;
            background-color: #95EC69;
            position: relative;
            margin-top: .1rem;
            margin-left: .2rem;
            padding: .1rem;
            border-radius: .1rem;
            float: right;
        }
        .text{
            max-width: 4.8rem;
            word-break: break-all;
            font-size: .28rem;
            line-height: .4rem;
        }
    }
    .leftinformation{
        margin-left: .2rem;
        margin-top: .1rem;


        .leftdialogue{
            max-width: 5rem;
            min-height: .2rem;
            display: flex;
            float: left;
            background-color: #fff;
            position: relative;
            margin-top: .1rem;
            margin-left: .2rem;
            padding: .1rem;
            border-radius: .1rem;

            .videoBox{
                width: 3rem;
                height: 4.5rem;
                position: relative;
                .button{
                    width: .8rem;
                    height: .8rem;
                    position: absolute;
                    top: 2rem;
                    left: 1.5rem;
                    margin-top: -.4rem;
                    margin-left: -.4rem;
                    //play
                    background-image: url(~@/assets/images/play.png);
                    background-size: 100% auto;
                    background-position: top;
                    background-repeat: no-repeat;
                }
            }
            .cardBox{
                width: 4.8rem;
                padding: .14rem 0;
                display: flex;

                .cardLeft{
                    width: 3.4rem;
                    margin-right: .2rem;

                    .textBox{
                        width: 3.4rem;
                        height: 1.2rem;
                        font-weight: bold;
                        font-size: .24rem;

                    }

                    .jump{
                        width: 1.32rem;
                        height: .32rem;
                        background-image: url(~@/assets/images/cardJump.png);
                        background-size: 100% auto;
                        background-position: top;
                        background-repeat: no-repeat;
                        margin-top: .2rem;


                    }

                }

                .cardRight{
                    width: 1.2rem;
                    height: 1.2rem;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }

            }


        }
        .text{
            max-width: 4.8rem;
            word-break: break-all;
            font-size: .28rem;
            line-height: .4rem;
            white-space: pre-wrap;
        }
    }
  }
  </style>